import fordSeoUrl from '@/config/seo-config-url/branded/ford';

export default {
    type: 'windowtitle',
    kbb: {
        srp: {
            windowtitlenational: '@@name@@ for Sale @@owner@@@@price@@@@mpg@@- Kelley Blue Book',
            windowtitlenationalextra: '@@name@@ for Sale @@owner@@@@price@@@@mpg@@: @@extra@@ - Kelley Blue Book',
            windowtitle: '@@name@@ for Sale @@owner@@@@price@@@@mpg@@in @@city@@, @@state@@ - Kelley Blue Book',
            windowtitleextra: '@@name@@ for Sale @@owner@@@@price@@@@mpg@@in @@city@@, @@state@@ @@zip@@: @@extra@@ - Kelley Blue Book',
        },
        vdp: {
            windowtitlenational: '@@title@@ for sale in @@city@@, @@state@@ @@zip@@: @@style@@ Details - @@id@@ | Kelley Blue Book',
        },
    },
    atc: {
        sfp: {
            windowtitlenational: 'Search for Cars For Sale Online - Find a Car at Autotrader',
            windowtitlenationalextra: 'Search for Cars For Sale Online - Find a Car at Autotrader',
            windowtitle: 'Search for Cars For Sale Online - Find a Car at Autotrader',
            windowtitleextra: 'Search for Cars For Sale Online - Find a Car at Autotrader',
        },
        srp: {
            windowtitlenational: '@@name@@ for Sale Near Me @@owner@@@@price@@@@mpg@@- Autotrader',
            windowtitlenationalextra: '@@name@@ for Sale Near Me @@owner@@@@price@@@@mpg@@: @@extra@@ (with Photos) - Autotrader',
            windowtitle: '@@name@@ for Sale @@owner@@@@price@@@@mpg@@in @@city@@, @@state@@ - Autotrader',
            windowtitleextra: '@@name@@ for Sale @@owner@@@@price@@@@mpg@@in @@city@@, @@state@@: @@extra@@ (with Photos) - Autotrader',
        },
        searchform: {
            windowtitlenational: 'Find new cars and used cars for sale at Autotrader. With millions of cars, find your next car at the most complete auto classifieds site online.',
            windowtitlenationalextra: 'Find new cars and used cars for sale at Autotrader. With millions of cars, find your next car at the most complete auto classifieds site online.',
            windowtitle: 'Find new cars and used cars for sale at Autotrader. With millions of cars, find your next car at the most complete auto classifieds site online.',
            windowtitleextra: 'Find new cars and used cars for sale at Autotrader. With millions of cars, find your next car at the most complete auto classifieds site online.',
        },
        vdp: {
            windowtitlenational: '@@title@@ for sale in @@city@@, @@state@@ @@zip@@: @@style@@ Details - @@id@@ - Autotrader',
        },
    },
    ford: {
        fordlanding: {
            windowtitlenational: 'Shop Ford Blue Advantage Certified Used Vehicles',
            windowtitlenationalextra: 'Shop Ford Blue Advantage Certified Used Vehicles',
            windowtitle: 'Shop Ford Blue Advantage Certified Used Vehicles',
            windowtitleextra: 'Shop Ford Blue Advantage Certified Used Vehicles',
        },
        srp: {
            windowtitlenational: `Certified Used @@name@@ for Sale @@owner@@@@price@@@@mpg@@ | ${fordSeoUrl.rootDomain}`,
            windowtitlenationalextra: `Certified Used @@name@@ for Sale @@owner@@@@price@@@@mpg@@: @@extra@@ | ${fordSeoUrl.rootDomain}`,
            windowtitle: `Certified Used @@name@@ for Sale @@owner@@@@price@@@@mpg@@in @@city@@, @@state@@ | ${fordSeoUrl.rootDomain}`,
            windowtitleextra: `Certified Used @@name@@ for Sale @@owner@@@@price@@@@mpg@@in @@city@@, @@state@@: @@extra@@ | ${fordSeoUrl.rootDomain}`,
        },
        vdp: {
            windowtitlenational: `Certified Used @@name@@ for Sale in @@city@@, @@state@@ @@zip@@: @@style@@ Details - @@id@@ - ${fordSeoUrl.rootDomain}`,
            windowtitlenationalextra: `Certified Used @@name@@ for Sale in @@city@@, @@state@@ @@zip@@: @@style@@ Details - @@id@@ - ${fordSeoUrl.rootDomain}`,
            windowtitle: `Certified Used @@name@@ for Sale in @@city@@, @@state@@ @@zip@@: @@style@@ Details - @@id@@ - ${fordSeoUrl.rootDomain}`,
            windowtitleextra: `Certified Used @@name@@ for Sale in @@city@@, @@state@@ @@zip@@: @@style@@ Details - @@id@@ - ${fordSeoUrl.rootDomain}`,
        },
        moneyBackGuarantee: {
            windowtitlenational: `Money Back Guaranteed Used @@name@@ for Sale @@owner@@@@price@@@@mpg@@ | ${fordSeoUrl.rootDomain}`,
            windowtitlenationalextra: `Money Back Guaranteed Used @@name@@ for Sale @@owner@@@@price@@@@mpg@@: @@extra@@ | ${fordSeoUrl.rootDomain}`,
            windowtitle: `Money Back Guaranteed Used @@name@@ for Sale @@owner@@@@price@@@@mpg@@in @@city@@, @@state@@ | ${fordSeoUrl.rootDomain}`,
            windowtitleextra: `Money Back Guaranteed Used @@name@@ for Sale @@owner@@@@price@@@@mpg@@in @@city@@, @@state@@: @@extra@@ | ${fordSeoUrl.rootDomain}`,
        },
        commercialVehicles: {
            windowtitlenational: `Certified Commercial Vehicles for Sale @@owner@@@@price@@@@mpg@@ | ${fordSeoUrl.rootDomain}`,
            windowtitlenationalextra: `Certified Commercial Vehicles for Sale @@owner@@@@price@@@@mpg@@: @@extra@@ | ${fordSeoUrl.rootDomain}`,
            windowtitle: `Certified Commercial Vehicles for Sale @@owner@@@@price@@@@mpg@@in @@city@@, @@state@@ | ${fordSeoUrl.rootDomain}`,
            windowtitleextra: `Certified Commercial Vehicles for Sale @@owner@@@@price@@@@mpg@@in @@city@@, @@state@@: @@extra@@ | ${fordSeoUrl.rootDomain}`,
        },
    },
};
