import { Cookies } from 'react-cookie';

import deepmerge from 'deepmerge';
import _get from 'lodash/get';

import { brands } from 'reaxl-brand';

import atcPageData from '@/config/page-data/branded/atc';
import fordPageData from '@/config/page-data/branded/ford';
import kbbPageData from '@/config/page-data/branded/kbb';
import commonPageData from '@/config/page-data/common';

const isMarketExtensionDma = (searchState = '') => {
    const nonMarketExtensionStates = ['HI', 'GU', 'AK', 'PR', 'AS', 'MP', 'VI', 'UM', 'AE', 'AP'];
    return !nonMarketExtensionStates.includes(searchState);
};

const ctCode = (t) => {
    let type = '';
    switch (t) {
        case 'New':
            type = 'n';
            break;
        case 'Certified':
            type = 'c';
            break;
        default:
            type = 'u';
    }
    return type;
};

const overallImpressionData = (data, cookies) => {
    const listing = _get(data, 'base', {});
    const owner = _get(listing, 'owner', {});
    const type = _get(listing, 'type', 'Used');
    const pricingDetail = _get(listing, 'pricingDetail', {});
    const specifications = _get(listing, 'specifications', {});
    return {
        dt: _get(owner, 'name', '') ? 'y' : 'n',
        ct: ctCode(type),
        pr: _get(pricingDetail, 'salePrice', '') ? 'y' : 'n',
        c: _get(listing, 'ownerId', null),
        pt: _get(listing, 'images', null) ? 'y' : 'n',
        v: _get(listing, 'id', ''),
        spt: _get(listing, 'birfStockPhotoFlag', null) ? 'y' : 'n',
        cmp: 'v_lstg',
        test_name: _get(cookies, 'exp', ''),
        ml: _get(specifications, 'mileage', null) ? 'y' : 'n',
    };
};

const biString = (prefix, value) => (value ? ['&', prefix, '=', value].join('') : '');

const biArray = (prefix, value) => {
    let biArrayString = '';
    if (value) {
        if (typeof value === 'string') {
            value = [value];
        }

        for (let index = 0; index < value.length; index++) {
            const i = index + 1;
            biArrayString = biArrayString.concat(biString(prefix + i, value[index]));
        }
    }
    return biArrayString;
};

const addAttr = (biCode) => (biCode ? [',', biCode].join('') : '');

const listingAttributes = (query) => {
    let attr = ''.concat(
        _get(query, 'historyReportOnly', false) ? addAttr('whr') : '',
        _get(query, 'photosOnlyActual', false) && _get(query, 'listingFeatures', '') === 'onlyPhotos' ? addAttr('wpt') : '',
        _get(query, 'listingFeatures', '') === 'onlyPrice' ? addAttr('wpr') : '',
        _get(query, 'listingFeatures', '') === 'onlyVideos' ? addAttr('wvd') : '',
        _get(query, 'specialOffersOnly', false) ? addAttr('wsp') : '',
        _get(query, 'specialOffersCashBackOnly', false) ? addAttr('wco') : '',
        _get(query, 'specialOffersFinanceOnly', false) ? addAttr('wsf') : '',
    );

    if (attr.length > 0) {
        attr = attr.slice(1);
    }

    return attr;
};

const biSearchString = (query) => (''.concat(
    biString('advanced', 'y'),
    biArray('dor_', _get(query, 'doorCode', null)),
    biArray('mpg_', _get(query, 'mpgRange', null)),
    biArray('dr_', _get(query, 'driveGroup', null)),
    biString('yeart', _get(query, 'endYear', null)),
    biArray('e_', _get(query, 'engineCode', null)),
    biArray('pnt_', _get(query, 'extColorSimple', null)),
    biArray('ipnt_', _get(query, 'intColorSimple', null)),
    biArray('f_', _get(query, 'fuelTypeGroup', null)),
    biString('saw', _get(query, 'zip', null) ? _get(query, 'searchRadius', null) : null),
    biArray('srch_typ_', _get(query, 'listingType', null)),
    biString('mi', _get(query, 'mileage', null)),
    biString('pmax', _get(query, 'maxPrice', 0) > 0 ? _get(query, 'maxPrice', null) : null),
    biString('pmin', _get(query, 'minPrice', 0) > 0 ? _get(query, 'minPrice', null) : null),
    biArray('fsb_', _get(query, 'sellerType', 'b')),
    biString('sb', _get(query, 'sortBy', '') !== 'derivedpriceDESC' ? _get(query, 'sortBy', 'relevance') : null),
    biString('lstg_attr_', listingAttributes(query)),
    biString('yearf', _get(query, 'startYear', null)),
    biArray('t_', _get(query, 'transmissionCode', null)),
    biString('z', _get(query, 'zip', null)),
    biString('kwd', encodeURI(_get(query, 'keywordPhrases', ''))),
    biArray('feat_', _get(query, 'featureCode', null)), // TODO: get feature code desc out of VehicleReferenceService
    biArray('veh_sty_', _get(query, 'vehicleStyleCode', []).includes('all') ? null : _get(query, 'vehicleStyleCodes', null)),
    biArray('bsst_', _get(query, 'bodyStyleSubType', null)),
    biArray('make_', _get(query, 'makeCode', null)),
    biArray('sers_', _get(query, 'seriesCode', null)),
    biArray('model_', _get(query, 'modelCode', null)),
    biArray('trm_', _get(query, 'trimCode', null) && _get(query, 'trimCode').includes('|') ? _get(query, 'trimCode', '|').split('|')[1] : null),
));

export default function addPageMetaData({
    pageName = '',
} = {}) {
    return async (ctx) => {
        // useSearchResultsPageData
        // move static to cfg files
        let brandedPageData = {};
        const { query, data, req } = ctx;
        const brand = _get(data, 'brand', 'atc');
        const legacyData = _get(data, 'pageData', {});
        const headers = _get(req, 'headers', {});
        const cookie = _get(headers, 'cookie', {});
        const cookies = new Cookies(cookie);
        switch (brand) {
            case brands.KBB_BRAND:
                brandedPageData = kbbPageData;
                break;
            case brands.FORD_BRAND:
                brandedPageData = fordPageData;
                break;

            default:
                brandedPageData = atcPageData;
        }

        const pageData = {
            kbbBaseUrl: process.env.kbbBaseUrl,
            searchCity: query.city,
            searchState: query.state,
            marketExtensionDma: isMarketExtensionDma(query.state),
        };
        if (pageName === 'vdp') {
            pageData.overallImpressionData = overallImpressionData(data, cookies);
        } else {
            pageData.biSearchString = biSearchString(query);
        }

        Object.assign(pageData, {
            ...commonPageData,
            ...brandedPageData,
        });

        // TODO we have multiple middlewares trying to set pageData and need to separate them out so we don't have to do
        // this stupid merge.  Probably should just get rid of pageData entirely
        ctx.data.pageData = deepmerge(legacyData, pageData);
    };
}
