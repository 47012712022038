export default function withExperiences() {
    return async (ctx) => {

        const {
            srp_buy_online: [enableSrpBuyOnline],
        } = ctx.useFeatures(['srp_buy_online']);

        // set up correct srp experience on page load
        if (typeof window === 'undefined' && enableSrpBuyOnline) {
            const { experience, homeServices } = ctx.query;

            const homeServicesValues = typeof homeServices === 'string' ? [homeServices] : homeServices;
            const includesBuyOnline = homeServicesValues && homeServicesValues.includes('BUY_ONLINE');

            if (experience === 'buy-online' && !includesBuyOnline) {
                ctx.query.homeServices = homeServicesValues ? [...homeServicesValues, 'BUY_ONLINE'] : ['BUY_ONLINE'];
            }
            if (!experience && includesBuyOnline) {
                ctx.query.experience = 'buy-online';
            }
        }
    };
}
